<template>
    <div class="grid grid-cols-1 md:grid-cols-12 gap-4 mt-4">
        <div class="col-span-8">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-10 lg:col-span-10">
                    <Dropdown 
                        :Items="dropdownItemsEnsaio"
                        label="Ensaio"
                        @item = "adicionarEnsaio($event)"
                    />
                </div>
                <div class="col-span-6 lg:col-span-6">
                    <Dropdown 
                        :Items="dropdownItemsFarmacopeia"
                        label="Farmacopeia"
                        @item = "adicionarFarmacopeia($event)"
                    />
                </div>
                <div class="col-span-6 lg:col-span-6 flex mt-3 ml-3 align-items-center">
                    <div class="flex flex-column justify-content-center align-items-center mr-3">
                        <router-link to="/groupProducts/ensaio" class="link">
                            <p>Ensaio</p>
                        </router-link>
                    </div>
                    <div class="flex flex-column justify-content-center align-items-center">
                        <router-link to="/groupProducts/farmacopeia" class="link danger">
                            <p>Farmacopeia</p>
                        </router-link>
                    </div>
                </div>
                <div class="col-span-12 lg:col-span-12">
                    <b-table :items="value.campoFarmacopeia" :fields="fields" striped responsive="lg">
                        <template #cell(ações)="data">
                            <div class="flex justify-center">
                                <b-button
                                    class="bg-transparent border-0"
                                    size="sm"
                                    @click="deleteEssay(data.item)"
                                >
                                    <DeleteIcon class="w-4  icon__actions" />
                                </b-button>
                            </div>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { BTable } from "bootstrap-vue";

    import Dropdown from "@/@core/components/commons/selectDropdown/Dropdown.vue";
    import DeleteIcon from "@/assets/img/delete.svg";

    export default {
        name: 'GeneralEnsaioInformation',
        components: {
            Dropdown,
            BTable,
            DeleteIcon,
        },
        props: {
            value: {
                type: Object,
            }
        },
        data() {
            return {
                fields: [
                { key: "descricao", label:"Descrição"}, 
                { key: "farmacopeiaDescricao", label:"Farmacopeia"},
                { key: "ações" }
                ],
                ensaio_img: require('/static/imgs/components/products/ensaio.png'),
                farmacopeia_img: require('/static/imgs/components/products/farmacopeia.png'),
                dropdownItemsEnsaio:[],
                dropdownItemsFarmacopeia:[],
            }
        },
        created () {
            this.ObterSeletorEnsaio();
            this.ObterSeletorFarmacopeia();
        },
        methods: {
            ObterSeletorEnsaio() {
            this.$http({
                url: `/ensaio/obter-seletor-ensaio`,
                method: "GET"
            }).then((response) => {
                    this.dropdownItemsEnsaio = response.data;
                })
            },
            ObterSeletorFarmacopeia() {
            this.$http({
                url: `/Farmacopeia/obter-seletor-Farmacopeia`,
                method: "GET"
            }).then((response) => {
                    this.dropdownItemsFarmacopeia = response.data;
                })
            },
            adicionarEnsaio(item) {
                if(this.value.campoFarmacopeia === null) {
                    this.value.campoFarmacopeia = [];
                }
                if(this.value.campoFarmacopeia?.includes(item) === false) {
                    this.value.campoFarmacopeia.push(item);
                }
            },
            adicionarFarmacopeia(item) {
                this.$http({
                    url: `/ensaio/obter-EnsaioFarmacopeia/${item.id}`,
                    method: "GET"
                }).then((response) => {
                    this.value.campoFarmacopeia.push(...response.data);
                })
            },
            deleteEssay (essay) {
                this.$http({
                    url: `/grupoProduto/remover-produtoEnsaio/${essay.id}`,
                    method: "POST"
                }).then(() => {
                        this.value.campoFarmacopeia.splice(this.value.campoFarmacopeia.indexOf(essay),1);
                    })
            },     
        }
    }
</script>

<style>
    .link {
        font-weight: bold;
        text-transform: uppercase;
        color: #e71f26;
    }
    .link:hover {
        text-decoration: none;
        color: #e2030a;
    }
</style>