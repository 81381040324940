<template>
    <ValidationObserver ref="observer" v-slot="{ validate }">
    <b-form @submit.stop.prevent="validate().then(Edit)">
        <section class="-mt-4">
            <b-tabs class="w-full">
                <b-tab title="Geral">
                    <div class="w-full flex flex-col justify-between">
                        <GeneralProductsInformation v-model="viewModel" class="mt-4"/>
                    </div>
                </b-tab>
                <b-tab title="Ensaio">
                    <GeneralEnsaioInformation v-model="viewModel"/>
                </b-tab>
            </b-tabs>
        </section>

        <div class="flex mt-3 pb-8 border__bottom">
            <span class="text-red-600">Os campos com * são de preenchimento obrigatório</span>
        </div>

        <footer class="w-full flex items-center justify-start gap-5 my-10">
        <Button
            class="bg-green-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-green-600 transition-all duration-300"
            buttonText="Confirmar"
            type="submit"
        />
        <Button
            class="bg-red-500 shadow shadow-[rgba(200, 200, 200, 0.7)] hover:bg-red-600 transition-all duration-300"
            buttonText="Cancelar"
            :onClick="redirectUrl"
        />
        </footer>
        <Modal
        v-if="modalIsOpen"
        :modalIsOpen.sync="modalIsOpen"
        :closeModal="closeModal"
        :modalText="$t('commons.modalTextEdit')"
        />

        <Modal
        v-if="modalError"
        :modalIsOpen.sync="modalError"
        :closeModal="closeModal"
        :modalText="$t('commons.modalErrorCreate')"
        />
        <Modal
        v-if="modalErroSalvar"
        :modalIsOpen.sync="modalErroSalvar"
        :closeModal="closeModal"
        :modalText="$t('commons.modalErroSalvar')"
        />
    </b-form>
</ValidationObserver>
</template>

<script>

import { BForm } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";

import GeneralProductsInformation from "./GeneralProductsInformation.vue";
import GeneralEnsaioInformation from "./GeneralEnsaioInformation.vue";
import Button from "@core/components/commons/button/Button";
import Modal from "@core/components/commons/modal/Modal";

export default {
    name: 'ManageProductsEdit',
    components: {
    BForm,
    ValidationObserver,
    GeneralProductsInformation,
    GeneralEnsaioInformation,
    Button,
    Modal,
},
    data() {
        return {
            modalIsOpen: false,
            modalError: false,
            modalErrorMessage: null,
            modalErroSalvar:false,
            viewModel: {
                id: null,
                descricao: null,
                comissao: null,
                desconto: null,
                descontoMaximo: null,
                markup: null,
                grupo: null,
                tipo: null,
                ativaControleLote: false,
                pesagemMonitoradaGrupo: false,
                campoFarmacopeia: [],
            }
        }
    },
    created () {
        this.RecuperarProduto(this.$router.currentRoute.params.id);
    },
    methods: {
        redirectUrl() {
            return this.$router.push(`/groupProducts`);
        },
        openModal() {
            this.modalIsOpen = true;
        },
        closeModal(event) {
            if (event.target === event.currentTarget) {
                this.modalIsOpen = false;
                this.modalError = false;
                this.modalErroSalvar = false;
                window.scrollTo({
                top: 0,
                behavior: "smooth",
                });
            }
        },
        RecuperarProduto (id) {
            this.$http({
                url: `/grupoProduto/obter/${id}`,
                method: "Get"
            }).then((response) => {
                this.viewModel.id = response.data.id;
                this.viewModel.descricao = response.data.descricao;
                this.viewModel.comissao = response.data.comissao;
                this.viewModel.desconto = response.data.desconto;
                this.viewModel.descontoMaximo = response.data.descontoMaximo;
                this.viewModel.markup = response.data.markup;
                this.viewModel.grupo = response.data.grupo;
                this.viewModel.tipo = response.data.tipo;
                this.viewModel.ativaControleLote = response.data.ativaControleLote;
                this.viewModel.pesagemMonitoradaGrupo = response.data.pesagemMonitoradaGrupo;      
                this.viewModel.campoFarmacopeia = response.data.campoFarmacopeia;       
            })
        },
        async Edit () {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                this.modalError = true;
                return
            }
            this.$http({
            url: `/grupoProduto/editar`,
            data: this.viewModel,
            params: { id: this.viewModel.id },
            method: "POST"
            }).then(() => {
            this.modalIsOpen = true;
            setTimeout(() => this.$router.push(`/groupProducts`), 3000);
            }).catch(() => this.modalErroSalvar = true)
        },
    },
}
</script>

<style scoped>
    .border__bottom {
        border-bottom: 1px solid #5b68735d;
    }
</style>